<template>
  <div class="clockin-list-page">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/admin/index' }">数据概览</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/admin/apps/index' }">应用中心</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/admin/apps/shell/index' }">会议壳</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/admin/apps/shell/meeting-list' }">会议列表</el-breadcrumb-item>
      <el-breadcrumb-item>{{this.$route.query.meetingName}}</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/admin/apps/shell/content/index/' + this.$route.params.mId }">会议内容管理</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/admin/apps/shell/content/clockin/' + this.$route.params.mId + '?itemName=' + this.$route.query.meetingName}">拍照打卡</el-breadcrumb-item>
      <el-breadcrumb-item>打卡列表</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="search-bar">
      <div class="left">
        <el-form :inline="true" :model="query" class="search-form-inline">
          <el-form-item>
            <el-input v-model="query.title" placeholder="请输入打卡人昵称" clearable @clear="getDataList"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="getDataList">查询</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="page-content" v-if="dataList.length > 0">
      <el-table :data="dataList" border style="width: 100%" max-height="500">
        <el-table-column prop="id" label="#" width="80"></el-table-column>
        <el-table-column prop="nick" label="用户昵称"></el-table-column>
        <el-table-column prop="title" label="上传时间">
          <template slot-scope="scope">
            {{datafromatfull(scope.row.creat_time)}}
          </template>
        </el-table-column>
        <el-table-column prop="status" label="上传图片">
          <template slot-scope="scope">
            <el-image style="width: 100px; height: 100px" :src="scope.row.url" :preview-src-list="[scope.row.url]"></el-image>
          </template>
        </el-table-column>
        <el-table-column prop="praiseNum" label="已点赞人次"></el-table-column>
        <el-table-column fixed="right" label="操作" width="330">
          <template slot-scope="scope">
            <el-button type="danger" size="small" @click="delItem(scope.row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="footer-pagination">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="query.currentPage" :page-sizes="[20, 50, 100]" :page-size="query.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="query.total"></el-pagination>
      </div>
    </div>
    <div class="no-list" v-else>
      <el-empty :image-size="200"></el-empty>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ClockinList',
  data () {
    return {
      query: {
        title: '',
        pageSize: 20,
        currentPage: 1,
        total: 0
      },
      dataList: []
    }
  },
  created () {
    this.getDataList()
  },
  methods: {
    async getDataList () {
      const { data: res } = await this.$http.get('/shell-clockin-photos-list/' + this.$route.params.clockinid, { params: this.query })
      if (res.status === 200) {
        this.dataList = res.data.list.data
        this.query.total = Number(res.data.list.total)
        this.query.currentPage = Number(res.data.list.current_page)
        this.query.pageSize = Number(res.data.pageSize)
      } else {
        this.$message.error(res.msg)
      }
    },
    // 删除某个打卡照片
    async delItem (id) {
      const { data: res } = await this.$http.delete('/shell-clockin-photos-delete', { params: { id: id } })
      if (res.status === 200) {
        this.$message.success(res.msg)
        await this.getDataList()
      } else {
        this.$message.error(res.msg)
      }
    },
    // 更改每页显示数量
    handleSizeChange (val) {
      this.query.pageSize = val
      this.getDataList()
    },
    // 更改页码
    handleCurrentChange (val) {
      this.query.currentPage = val
      this.getDataList()
    },
    datafromatfull (time) {
      return this.$moment.unix(time).format('YYYY-MM-DD HH:mm')
    }
  }
}
</script>

<style scoped>
.search-bar{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.search-form-inline .el-form-item{
  margin-bottom: 0;
}
.add-form{
  padding: 0 40px;
  text-align: left;
}
/deep/.el-form--label-top .el-form-item__label{
  padding: 0;
}
.el-form-item{
  margin-bottom: 8px;
}
.el-descriptions{
  padding: 0 40px;
}
.el-radio-group .el-radio{
  margin-bottom: 10px;
}
</style>
